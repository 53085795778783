import axios from 'axios';
import { useEffect, useState } from 'react';
import { IoCloseCircleOutline } from 'react-icons/io5';
import { useAuthContext } from '../../contexts/AuthContext';
import { theme } from '../../shared/constants';
import { httpConfig } from '../../shared/localStorageHelper';
import { pbxAxiosInstance } from '../../shared/requests';
import { baseUrl, endpoints } from '../../shared/urls';


interface IAddOrgModal {
    toggleModal: boolean;
    setToggleModal: React.Dispatch<React.SetStateAction<boolean>>;
    setToggleAddOrgModal: React.Dispatch<React.SetStateAction<boolean>>;
    disableCancel: boolean;
    refetchOrgs: boolean;
    setRefetchOrgs: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ChooseOrgModal = ({
    toggleModal,
    setToggleModal,
    setToggleAddOrgModal,
    disableCancel,
    refetchOrgs,
    setRefetchOrgs
}: IAddOrgModal) => {
    const [loading, setLoading] = useState(false);
    const [currentOrg, setCurrentOrg] = useState('');
    const [org, setOrg] = useState<any>([]);
    const [selectedOrg, setSelectedOrg] = useState('');

    const { user } = useAuthContext();
    const parsedUser = JSON.parse(user || '{}');

    const fetchAllOrg = async (url: string) => {
        try {
            setLoading(true);
            const config = await httpConfig();
            const { data } = await pbxAxiosInstance({
                url,
                headers: config.headers,
                method: 'GET',
            });

            setOrg(data);
            const activeOrgId = parsedUser?.profile.active_organization;
            const currentOrgName = localStorage.getItem('selectedOrg');
            localStorage.setItem(
                'active_role',
                parsedUser?.profile.active_role,
            );
            if (currentOrgName) {
                setCurrentOrg(currentOrgName);
            } else {
                data.map((d: { id: any; name: any }) => {
                    if (d.id === activeOrgId) {
                        localStorage.setItem('selectedOrg', d.name);
                        setCurrentOrg(d.name);
                    }
                });
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const changeCurrentOrg = async () => {
        if (selectedOrg) {
            try {
                const token = localStorage.getItem('accessToken');
                const { data } = await axios({
                    url: `${baseUrl}${endpoints.activeOrg}`,
                    method: 'POST',
                    data: {
                        active_organization: selectedOrg,
                    },
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                localStorage.setItem('active_role', data.active_role);
                localStorage.setItem('selectedOrgPlan', data.active_organization.plan);
                window.location.reload();
            } catch (error) {
                console.log(error);
            }
        }
    };

    useEffect(() => {
        org.map((o: { id: string; name: string }) => {
            if (o.id === selectedOrg) {
                localStorage.setItem('selectedOrg', o.name);
            }
        });
        changeCurrentOrg();
    }, [selectedOrg]);

    useEffect(() => {
        if (refetchOrgs) {
            (async () => {
                await fetchAllOrg(`${endpoints.getAllOrgs}`);
            })();
            setRefetchOrgs(false);
        }
    }, [refetchOrgs]);


    return toggleModal ? (
        <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative m-6 sm:mx-auto w-auto sm:w-96" >
                    <div className="border-0 mx-auto rounded-lg shadow-lg relative flex flex-col   bg-white outline-none focus:outline-none">
                        <div className="flex items-start justify-between px-6 py-3 border-b border-solid border-slate-200 rounded-t">
                            <h6 className="text-xl font-semibold my-auto">
                                Choose Organization
                            </h6>
                            <button
                                className="p-1  rounded-full bg-transparent border-0 text-brand-d4 float-right text-xl leading-none font-semibold outline-none focus:outline-none hover:text-brand"
                                onClick={() => setToggleModal(false)}
                            >
                                {!disableCancel ? (
                                    <IoCloseCircleOutline
                                        fill={theme.brand}
                                        size={30}
                                    />
                                ) : (
                                    <></>
                                )}
                            </button>
                        </div>
                        <div className="relative p-6 flex-auto max-h-96 overflow-y-auto">
                            <button onClick={e => { setToggleAddOrgModal(true); setToggleModal(false); }} type="button" className="w-full block text-white bg-brand-d1 hover:bg-brand-d2 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2">Create Organization</button>
                            {org.map((org: any) => (
                                <button key={org.id} onClick={e => { setSelectedOrg(org.id) }} type="button" className="w-full block py-2.5 px-5 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-brand-l4 rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 ">{org.name}</button>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
    ) : (
        <></>
    );
};
