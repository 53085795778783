import React from 'react';

interface IInputField {
    label: string;
    value: string | number | readonly string[] | undefined;
    // setValue: React.Dispatch<React.SetStateAction<string>>;
    setValue: (e: React.ChangeEvent<any>) => void;
    // onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    id: string;
    InputProps?: React.HTMLProps<HTMLInputElement>;
    placeholder: string;
    helperText?: string | undefined | boolean;
    size?: 'sm' | 'md';
    textarea?: boolean;
    labelVisibility?: boolean;
}

export const InputField: React.FC<IInputField> = ({
    label,
    value,
    setValue,
    id,
    size = 'md',
    placeholder,
    helperText,
    InputProps,
    textarea = false,
    labelVisibility = true,
}) => {
    return (
        <div className="flex flex-col">
            {labelVisibility && (
                <label
                    className={`text-gray-500 ${size === 'sm' ? 'text-px1' : 'text-h5'
                        } font-medium mb-2`}
                    htmlFor={id}
                >
                    {label}
                </label>
            )}
            {textarea ? (
                <textarea
                    id={id}
                    placeholder={placeholder}
                    className={`border text-black font-medium ${size === 'sm'
                        ? 'text-px3 px-2 py-1.5'
                        : 'text-px1 px-4 py-3.5'
                        } border-gray-400 border rounded-default placeholder-gray-900::placeholder focus:outline-none focus:ring-2 focus:ring-blue focus:border-transparent disabled:bg-gray-900 disabled:cursor-not-allowed disabled:text-white`}
                    style={{
                        minHeight: size === 'sm' ? '38px' : 'auto',
                        background: '#F3F4F6',
                    }}
                    value={value}
                    onChange={setValue}
                />
            ) : (
                <input
                    className={`border text-black font-medium ${size === 'sm'
                        ? 'text-px3 px-2 py-2.5'
                        : 'text-px1 px-4 py-2.5'
                        } bg-gray-100 border-gray-400 border rounded-default placeholder-gray-900::placeholder hover:border-gray-500 hover:bg-gray-50
                    focus:outline-none focus:ring-2 focus:ring-blue focus:border-transparent focus:bg-white disabled:bg-gray-200 disabled:cursor-not-allowed disabled:text-black`}
                    id={id}
                    placeholder={placeholder}
                    value={value}
                    onChange={setValue}
                    // onChange={onChange}
                    {...InputProps}
                />
            )}
            <p className="text-label text-red mt-2 font-medium">{helperText}</p>
        </div>
    );
};
