import axios from 'axios';
import { Formik } from 'formik';
import { IoCloseCircleOutline } from 'react-icons/io5';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { Button } from '../Buttons/Button';
import { PX1 } from '../../components/Typography';
import { theme } from '../../shared/constants';
import { baseUrl, endpoints } from '../../shared/urls';
import { InputField } from '../InputFields/InputField';

interface IAddOrgModal {
    toggleModal: boolean;
    setToggleModal: React.Dispatch<React.SetStateAction<boolean>>;
    setToggleChooseOrgModal: React.Dispatch<React.SetStateAction<boolean>>;
    disableCancel: boolean;
    setRefetchOrgs: React.Dispatch<React.SetStateAction<boolean>>;
}

const CreateOrgValidationSchema = Yup.object().shape({
    name: Yup.string().required('Organization name  is required.'),
    phone: Yup.string().required('Phone is required.'),
});

export const AddOrgModal = ({
    toggleModal,
    setToggleModal,
    setToggleChooseOrgModal,
    disableCancel,
    setRefetchOrgs,
}: IAddOrgModal) => {
    return toggleModal ? (
        <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-auto my-6 mx-auto max-w-3xl">
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        <div className="flex items-start justify-between px-6 py-3 border-b border-solid border-slate-200 rounded-t">
                            <h6 className="text-xl font-semibold my-auto">
                                Create Organization
                            </h6>
                            <button
                                className="p-1  rounded-full bg-transparent border-0 text-brand-d4 float-right text-xl leading-none font-semibold outline-none focus:outline-none hover:text-brand"
                                onClick={() => setToggleModal(false)}
                            >
                                {!disableCancel ? (
                                    <IoCloseCircleOutline
                                        fill={theme.brand}
                                        size={30}
                                    />
                                ) : (
                                    <></>
                                )}
                            </button>
                        </div>
                        <div className="relative p-6 flex-auto">
                            <Formik
                                initialValues={{
                                    name: '',
                                    phone: '',
                                }}
                                validationSchema={CreateOrgValidationSchema}
                                onSubmit={(
                                    values,
                                    { setSubmitting, resetForm, setFieldError },
                                ) => {
                                    setTimeout(async () => {
                                        try {
                                            const token =
                                                localStorage.getItem(
                                                    'accessToken',
                                                );
                                            const { data } = await axios({
                                                url: `${baseUrl}${endpoints.organization}`,
                                                method: 'POST',
                                                data: {
                                                    name: values.name,
                                                    phone: values.phone,
                                                },
                                                headers: {
                                                    Authorization: `Bearer ${token}`,
                                                },
                                            });
                                            resetForm({});
                                            setRefetchOrgs(true);
                                            setToggleModal(false);
                                            toast(
                                                'New Organization Created Successfully.',
                                                {
                                                    type: 'success',
                                                },
                                            );
                                        } catch (error) {
                                            if (axios.isAxiosError(error)) {
                                                const errors =
                                                    error.response?.data;
                                                if (
                                                    errors.name ||
                                                    errors.phone
                                                ) {
                                                    errors.name &&
                                                        setFieldError(
                                                            'name',
                                                            errors.name,
                                                        );
                                                    errors.phone &&
                                                        setFieldError(
                                                            'phone',
                                                            errors.phone,
                                                        );
                                                } else {
                                                    toast(
                                                        'Something went wrong, please try again later.',
                                                        {
                                                            type: 'error',
                                                        },
                                                    );
                                                }
                                            }
                                        } finally {
                                            setSubmitting(false);
                                        }
                                    }, 200);
                                }}
                            >
                                {({
                                    values,
                                    errors,
                                    handleChange,
                                    touched,
                                    handleSubmit,
                                    setFieldValue,
                                    isSubmitting,
                                }) => (
                                    <form
                                        className="pb-2 w-64 md:w-96 mx-auto"
                                        onSubmit={handleSubmit}
                                    >
                                        <div className="mt-4">
                                            <InputField
                                                value={values.name}
                                                setValue={handleChange}
                                                id="name"
                                                label="Organization Name"
                                                placeholder="XYZ"
                                                helperText={
                                                    touched.name && errors.name
                                                }
                                            />
                                        </div>
                                        <div className="mt-4">
                                            <InputField
                                                value={values.phone}
                                                setValue={handleChange}
                                                id="phone"
                                                label="Phone Number"
                                                placeholder="1234567890"
                                                helperText={
                                                    touched.phone &&
                                                    errors.phone
                                                }
                                            />
                                        </div>
                                        <div className="flex justify-between mt-6 mb-6">
                                            <Button
                                                size="md"
                                                type="button"
                                                onClick={() =>
                                                    setToggleModal(false)
                                                }
                                                disabled={disableCancel}
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                loading={isSubmitting}
                                                disabled={isSubmitting}
                                                type="submit"
                                                uiType="brand"
                                                size="md"
                                            >
                                                Submit
                                            </Button>
                                        </div>
                                        <span
                                            onClick={e => {
                                                setToggleChooseOrgModal(true);
                                                setToggleModal(false);
                                            }}
                                        >
                                            <PX1 colorClass="text-blue hover:underline cursor-pointer">
                                                Choose an existing Organization?
                                            </PX1>
                                        </span>
                                    </form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
    ) : (
        <></>
    );
};
