import Menu, { MenuProps } from '@mui/material/Menu';
import { styled } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { AiFillQuestionCircle } from 'react-icons/ai';
import { HiSwitchHorizontal } from 'react-icons/hi';
import { IoNotificationsCircle, IoPersonCircleSharp } from 'react-icons/io5';
import { MdGroupAdd, MdMenu, MdOutlineLogout, MdPerson } from 'react-icons/md';
import { Link } from 'react-router-dom';
import '../components/Upgrade/upgrade.css';
import { useAuthContext } from '../contexts/AuthContext';
import { useSidebarContext } from '../contexts/SidebarContext';
import { AddOrgModal } from '../elements/Modals/AddOrgModal';
import { ChooseOrgModal } from '../elements/Modals/ChooseOrgModal';
import { theme } from '../shared/constants';
import DynopiiLogo from './../assets/dynopii-logo.png';
import { H5 } from './Typography';
interface INavBar {
    isLoggedIn?: boolean;
}
export const Navbar: React.FC<INavBar> = ({ isLoggedIn = false }) => {
    const [anchorMainEl, setAnchorMainEl] = React.useState<null | HTMLElement>(
        null,
    );
    const [anchorNotificationsEl, setAnchorNotificationsEl] = React.useState<null | HTMLElement>(
        null,
    );
    const mainMenuOpen = Boolean(anchorMainEl);
    const mainNotificationMenuOpen = Boolean(anchorNotificationsEl);
    const handleMainMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorMainEl(event.currentTarget);
    };
    const handleMainMenuClose = () => {
        setAnchorMainEl(null);
    };
    const handleNotificationsMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorNotificationsEl(event.currentTarget);
    };
    const handleNotificationMenuClose = () => {
        setAnchorNotificationsEl(null);
    };
    const [toggleAddOrgModal, setToggleAddOrgModal] = useState<boolean>(false);
    const [toggleChooseOrgModal, setToggleChooseOrgModal] =
        useState<boolean>(false);
    const [refetchOrgs, setRefetchOrgs] = useState<boolean>(true);
    const [disableCancel, setDisableCancel] = useState<boolean>(false);
    const [selectedOrg, setSelectedOrg] = useState(
        localStorage.getItem('selectedOrg'),
    );
    const [Duser, setDUser] = useState(localStorage.getItem('user'));
    const { setSidebarActive, sidebarActive } = useSidebarContext();
    const { user } = useAuthContext();
    const parsedUser = JSON.parse(user || '{}');

    const logoutUser = () => {
        localStorage.clear();
        setDUser('');
        window.location.href = "/";
    };

    useEffect(() => {
        if (!selectedOrg && !refetchOrgs) {
            setToggleChooseOrgModal(true);
        }
    }, [selectedOrg]);

    return (
        <div
            className={`flex items-center flex-1  ${isLoggedIn ? '' : 'py-2 max-w-screen-xl mx-auto'
                }`}
        >
            <div
                onClick={() => setSidebarActive(p => !p)}
                className="mr-4 cursor-pointer hover:bg-brand-l3 p-2 bg-hover rounded-full"
            >
                {isLoggedIn ? (
                    sidebarActive ? (
                        <MdMenu size={20} />
                    ) : (
                        <MdMenu size={20} />
                    )
                ) : null}
            </div>
            <Link to="/">
                <div className="flex items-center">
                    <div className={`w-6 h-6 overflow-hidden mr-1`}>
                        <img
                            src={DynopiiLogo}
                            alt="Dynopii Logo"
                            className="object-cover h-full w-full"
                        />
                    </div>
                    <span className="hidden sm:block">
                        <H5 fontWeight="semibold">DialSense</H5>
                    </span>
                </div>
            </Link>
            {isLoggedIn && (
                <div className="flex max-w-60 sm:max-w-80 lg:max-w-96 items-center right-0 absolute">
                    <Link type="button" to="//docs.dialsense.io" target={"_blank"} className=" hover:bg-brand-l3 py-1 px-1 rounded inline-flex items-center mr-2">
                        <AiFillQuestionCircle
                            fill={theme.darkBrand2}
                            size={28}
                        />
                    </Link>
                    <button
                        onClick={handleNotificationsMenuClick}
                        className=" hover:bg-brand-l3 py-1 px-1 rounded inline-flex items-center mr-2"
                    >
                        <IoNotificationsCircle
                            fill={theme.darkBrand2}
                            size={30}
                        />
                        <span className="flex -ml-3 h-3 w-3">
                            <span className="animate-ping relative inline-flex rounded-full h-3 w-3 bg-red-500"></span>
                        </span>
                    </button>
                    <button
                        onClick={handleMainMenuClick}
                        className="mr-3 hover:bg-brand-l3 py-1 px-1 rounded inline-flex items-center"
                    >
                        <IoPersonCircleSharp
                            fill={theme.darkBrand2}
                            size={30}
                        />
                        <span className="ml-3 hidden sm:block">
                            {parsedUser?.username}
                        </span>
                        <svg
                            className="-mr-1 ml-2 h-5 w-5"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                        >
                            <path
                                fillRule="evenodd"
                                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                clipRule="evenodd"
                            />
                        </svg>
                    </button>
                    <AddOrgModal
                        toggleModal={toggleAddOrgModal}
                        setToggleModal={setToggleAddOrgModal}
                        setToggleChooseOrgModal={setToggleChooseOrgModal}
                        disableCancel={disableCancel}
                        setRefetchOrgs={setRefetchOrgs}
                    />
                    <ChooseOrgModal
                        toggleModal={toggleChooseOrgModal}
                        setToggleModal={setToggleChooseOrgModal}
                        setToggleAddOrgModal={setToggleAddOrgModal}
                        disableCancel={disableCancel}
                        refetchOrgs={refetchOrgs}
                        setRefetchOrgs={setRefetchOrgs}
                    />
                </div>
            )}
            <StyledMainMenu
                id="main-menu"
                anchorEl={anchorMainEl}
                open={mainMenuOpen}
                onClose={handleMainMenuClose}
                className="p-8"
            >
                <div className="px-4 pt-3 pb-2">{parsedUser?.email}</div>
                <span className="block w-full px-4 pb-2">
                    <span className="text-sm">Active Organization:</span>
                    <br />
                    <span className="font-bold">{selectedOrg}</span>
                </span>
                <hr className="my-2" />
                <Link to="/profile">
                    <button
                        onClick={handleMainMenuClose}
                        type="button"
                        className="w-full px-4 py-2 font-medium text-left cursor-pointer hover:bg-brand-l4"
                    >
                        <div className="flex items-center">
                            <MdPerson size="25px" />
                            <div className="ml-2">Profile</div>
                        </div>
                    </button>
                </Link>
                <div className="w-60">
                    <div>
                        <button
                            type="button"
                            onClick={() => {
                                setToggleChooseOrgModal(true);
                                handleMainMenuClose();
                            }}
                            className="w-full px-4 py-2 font-medium text-left cursor-pointer hover:bg-brand-l4"
                        >
                            <div className="flex items-center">
                                <HiSwitchHorizontal size="25px" />
                                <div className="ml-2">Switch Organization</div>
                            </div>
                        </button>
                        <button
                            type="button"
                            onClick={() => {
                                setToggleAddOrgModal(true);
                                handleMainMenuClose();
                            }}
                            className="w-full px-4 py-2 font-medium text-left cursor-pointer hover:bg-brand-l4"
                        >
                            <div className="flex items-center">
                                <MdGroupAdd size="25px" />
                                <div className="ml-2">Create Organization</div>
                            </div>
                        </button>
                    </div>
                </div>
                <button
                    onClick={logoutUser}
                    type="button"
                    className="w-full px-4 py-2 font-medium text-left cursor-pointer hover:bg-brand-l4"
                >
                    <div className="flex items-center">
                        <MdOutlineLogout size="25px" />
                        <div className="ml-2">Logout</div>
                    </div>
                </button>
            </StyledMainMenu>
            {anchorNotificationsEl ? (
                <StyledMainMenu
                    id="notification-menu"
                    anchorEl={anchorNotificationsEl}
                    open={mainNotificationMenuOpen}
                    onClose={handleNotificationMenuClose}
                    className="p-8"
                >
                    <span className="block w-80 px-4 pb-2">
                        This is a test notification. A very long test notification.
                    </span>
                </StyledMainMenu>
            ) : (
                <></>
            )}
        </div>
    );
};

const StyledMainMenu = styled((props: MenuProps) => (
    <Menu
        elevation={10}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        boxShadow: '0 5px 20px rgba(0,0,0,0.07)',
        '& .MuiMenu-list': {
            border: '1px solid #dddddd',
        },
    },
}));
