import axios from 'axios';
import { httpConfig } from './localStorageHelper';
import { baseUrl, endpoints } from './urls';

export const pbxAxiosInstance = axios.create({
    baseURL: baseUrl,
});

export const isAxiosError = axios.isAxiosError;

pbxAxiosInstance.defaults.headers.post['Content-Type'] = 'application/json';

pbxAxiosInstance.interceptors.response.use(
    res => res,
    error => {
        const status = error.response ? error.response.status : null;
        if (status === 401) {
            // if (error?.response?.data?.code === "token_not_valid") {
            verifyToken().catch(error => {
                console.log(error?.response);
                if (error?.response?.data?.errors[0].code === 'token_not_valid') {
                    refreshToken()
                        .then(val => {
                            localStorage.setItem(
                                'accessToken',
                                val.data.access,
                            );
                            window.location.reload();
                        })
                        .catch(() => {
                            window.location.replace('/auth/login');
                            localStorage.removeItem('accessToken');
                            localStorage.removeItem('refreshToken');
                            localStorage.removeItem('user');
                            localStorage.removeItem('active_role');
                        });
                }
            });
        }
        return Promise.reject(error);
    },
);

export const verifyToken = async () => {
    const config = await httpConfig();
    const accessToken = localStorage.getItem('accessToken');
    return pbxAxiosInstance({
        url: `${endpoints.verifyToken}`,
        headers: config.headers,
        method: 'POST',
        data: {
            token: accessToken,
        },
    });
};

export const refreshToken = async () =>
    pbxAxiosInstance({
        url: `${endpoints.refreshToken}`,
        data: {
            refresh: localStorage.getItem('refreshToken'),
        },
        method: 'POST',
    });