import { permissions } from './permissions';

export const checkPermission = (resourceString: string, userData: any) => {
    const userRole = userData;
    const resourcePath = resourceString.split('.');
    resourcePath.push(userRole);
    type ObjectKey = keyof typeof permissions;
    let resource: any = permissions;
    for (let i = 0; i < resourcePath.length; i++) {
        resource = resource[resourcePath[i] as ObjectKey];
        if (!resource) {
            break;
        }
    }
    return resource;
};
