import { Suspense } from 'react';
import { StaticContext } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import { PageLoader } from './common/PageLoader';

export const withSuspense = (WrappedComponent: any) => {
    // eslint-disable-next-line react/display-name
    return (props: RouteComponentProps<{}, StaticContext, unknown>) => (
        <Suspense fallback={null} >
            <WrappedComponent {...props} />
        </Suspense>
    );
};
