import React, { createContext, useContext, useEffect, useState } from 'react';
// import { setAccessToken } from '../config/accessToken';
import { useLocation } from 'react-router-dom';

export const AuthContext = createContext<{
    user: any;
    userType: string | null;
    setUser: Function;
    setUserType: Function;
    removeUserInfo: Function;
    // updateUserInfo: Function;
    setUserInfo: Function;
}>({
    user: '',
    userType: '',
    setUser: () => {},
    removeUserInfo: () => {},
    setUserType: () => {},
    // updateUserInfo: () => {},
    setUserInfo: () => {},
});

interface IAuthProvider {
    children: React.ReactElement;
}

interface IsetUserInfo {
    user: any;
    access_token: string;
    refresh_token: string;
}

export const AuthProvider: React.FC<IAuthProvider> = ({ children }) => {
    const location = useLocation();
    const [user, setUser] = useState(localStorage.getItem('user'));
    const [userType, setUserType] = useState(
        localStorage.getItem('active_role'),
    );

    useEffect(() => {
        if (
            !localStorage.getItem('user') &&
            location.pathname == '/login/' &&
            location.search != ''
        ) {
            localStorage.setItem('pathName', location.search.split('=')[1]);
        }
    }, [location.pathname]);

    const setUserInfo = ({
        user,   
        access_token,
        refresh_token,
    }: IsetUserInfo) => {
        localStorage.setItem('refreshToken', refresh_token);
        localStorage.setItem('accessToken', access_token);
        localStorage.setItem('active_role', user.profile.active_role);
        setUser(JSON.stringify(user));
        localStorage.setItem('user', JSON.stringify(user));
    };

    const removeUserInfo = () => {
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('accessToken');
        localStorage.removeItem('user');
        localStorage.removeItem('active_role');
        setUser('');
    };

    // const updateUserInfo = (user: TUser) => setUser((x) => ({ ...x, ...user }));

    return (
        <AuthContext.Provider
            value={{
                user,
                userType,
                setUser,
                setUserType,
                setUserInfo,
                // updateUserInfo,
                removeUserInfo,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export const useAuthContext = () => useContext(AuthContext);

export default AuthContext;
