export const theme = {
    brand: '#4C6FFF',
    lightBrand: '#496cff',
    darkBrand: '#0024b6',
    lightBrand2: '#92a7ff',
    darkBrand2: '#00156d',
    lightBrand3: '#dbe2ff',
    darkBrand3: '#000724',
    lightGray: '#E4ECF7',
    hoverGray: '#CDD4DE',
    white: '#FFFFFF',
    hoverWhite: '#E5E5E5',
    green: '#66CB9F',
    darkGreen: '#5AB28C',
    orange: '#F7936F',
    darkOrange: '#DE8464',
    red: '#F16063',
    darkRed: '#D95659',
    blue: '#4C6FFF',
    darkBlue: '#4464E5',
    black: '#000000',
    eaeaea: '#EAEAEA',
    gray111: '#111111',
    gray222: '#222222',
    gray333: '#333333',
    gray444: '#444444',
    gray555: '#555555',
    gray666: '#666666',
    gray777: '#777777',
    gray888: '#888888',
    gray999: '#999999',
    grayf2f: '#F2F2F2',
};

type Taction = 'modification' | 'deletion';
type TactionSuccess =
    | 'modified'
    | 'deleted'
    | 'created'
    | 'uploaded'
    | 'placed'
    | 'published'
    | 'saved';

export const errorMessages = {
    // API
    default: 'Something went wrong, please try again later.',
    notFound: (resource?: string) => `${resource || 'Resource'} not found.`,
    pageNotFound: 'Page not found.',
    // UI
    // While selecting the rows for various operations from table.
    selectAtLeastOne: (action: Taction, resource?: string) =>
        `Please select at least 1 ${resource || 'resource'} for ${action}.`,
    selectOnlyOne: (action: Taction, resource?: string) =>
        `Please select only 1 ${resource || 'resource'} for ${action}.`,
};

export const successMessages = {
    // API
    actionSuccess: (action: TactionSuccess, resource?: string) =>
        `Successfully ${action} ${resource || 'resource'}`,
};

export const regexConstants = {
    beginsWith: 'BEGINS_WITH',
    contains: 'CONTAINS',
    endsWith: 'ENDS_WITH',
    exactly: 'EXACTLY',
};

export const dispositionConstants = {
    allDispositions: 'ALL_DISPOSITIONS',
    answered: 'ANSWERED',
    busy: 'BUSY',
    failed: 'FAILED',
    noAnswer: 'NO_ANSWER',
};

export const orderConstants = {
    ascending: 'ASCENDING',
    descending: 'DESCENDING',
};

export const groupByConstants = [
    {
        label: 'Account Information',
    },
    {
        value: 'ACCOUNT_CODE',
        label: 'Account Code',
    },
    {
        value: 'USER_FIELD',
        label: 'User Field',
    },
    {
        label: 'Date/Time',
    },
    {
        value: 'MINUTE',
        label: 'Minute',
    },
    {
        value: '10_MINUTE',
        label: '10 Minute',
    },
    {
        value: 'HOUR',
        label: 'Hour',
    },
    {
        value: 'HOUR_OF_DAY',
        label: 'Hour of Day',
    },
    {
        value: 'DAY_OF_WEEK',
        label: 'Day of Week',
    },
    {
        value: 'DAY',
        label: 'Day',
    },
    {
        value: 'WEEK',
        label: 'Week (Sun - Sat)',
    },
    {
        value: 'MONTH',
        label: 'Month',
    },
    {
        label: 'Telephone Number',
    },
    {
        value: 'DESTINATION_NUMBER',
        label: 'Destination Number',
    },
    {
        value: 'DID',
        label: 'DID',
    },
    {
        value: 'CALLER_ID_NUMBER',
        label: 'Caller ID Number',
    },
    {
        value: 'CALLER_ID_NAME',
        label: 'Caller ID Name',
    },
    {
        value: 'OUTBOUND_CALLER_ID_NUMBER',
        label: 'Outbound Caller ID Number',
    },
    {
        value: 'OUTBOUND_CALLER_ID_NAME',
        label: 'Outbound Caller ID Name',
    },
    {
        value: 'DESTINATION_CALLER_ID_NAME',
        label: 'Destination Caller ID Name',
    },
    {
        label: 'Tech Info',
    },
    {
        value: 'Disposition',
        label: 'DISPOSITION',
    },
    {
        value: 'DISPOSITION_BY_DAY',
        label: 'Disposition by Day',
    },
    {
        value: 'DISPOSITION_BY_HOUR',
        label: 'Disposition by Hour',
    },
    {
        value: 'DESTINATION_CONTEXT',
        label: 'Destination context',
    },
];

export const modalStyle = {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: '#ffffff',
    overflow: 'auto',
    boxShadow: 24,
    border: 0,
    borderRadius: '6px',
};
export const tiers = [
    {
        title: 'Basic',
        price: 1000,
        description: ['Feature 1', 'Feature 2', 'Feature 3', 'Feature 4'],
        buttonText: 'Purchase',
    },
    {
        title: 'Pro',
        subheader: 'Most popular',
        price: 2000,
        description: ['Feature 1', 'Feature 2', 'Feature 3', 'Feature 4'],
        buttonText: 'Purchase',
    },
    {
        title: 'Enterprise',
        price: 3000,
        description: ['Feature 1', 'Feature 2', 'Feature 3', 'Feature 4'],
        buttonText: 'Purchase',
    },
];
export const languages = [
    'bn-IN',
    'en-AU',
    'en-BZ',
    'en-CA',
    'en-cb',
    'en-GB',
    'en-IE',
    'en-IN',
    'en-JM',
    'en-MT',
    'en-MY',
    'en-NZ',
    'en-PH',
    'en-SG',
    'en-TT',
    'en-US',
    'en-ZA',
    'en-ZW',
    'en_IN',
];

export const codecs = ['gsm', 'ulaw', 'alaw', 'g729'];

export const E164PhoneValidationRegex = /^\+[1-9]\d{10,14}$/;

export const dispositions: { [key: string]: string } = {
    NEW: 'New Lead - Lead has not been called, or cannot be called',
    QUEUE: 'Lead To Be Called - Lead is about to be sent to an agent',
    INCALL: 'Lead Being Called - Agent is talking to the lead',
    DROP: 'Agent Not Available - Call was dropped while customer was waiting for an agent',
    XDROP: 'Agent Not Available IN - Call was dropped while customer was waiting for an agent on an inbound call',
    NA: 'No Answer AutoDial - Any outbound call that does not receive an Answer signal(or other signal) from the carrier. This can include ring-no-answer, disconnected, carrier congestion and other errors',
    CALLBK: 'Call Back - Callback, both scheduled and non-scheduled',
    CBHOLD: "Call Back Hold - Scheduled ANYONE callback that has not hit it's trigger, or an AGENTONLY callback",
    A: 'Answering Machine - Agent-defined Answering Machine',
    AA: 'Answering Machine Auto - Dialer-defined Answering Machine',
    AM: 'Answering Machine Sent to Mesg - AMD(Answering Machine Detection) call sent to a message to be played',
    AL: 'Answering Machine Msg Played - AMD(Answering Machine Detection) call sent to a message and message has been played',
    AMDXFR: 'Answering Machine Transfer to Call Menu or In-Group',
    AFAX: 'Fax Machine Auto - Dialer-defined Fax Machine',
    B: 'Busy - Agent-defined Busy signal',
    AB: 'Busy Auto - Carrier-received Busy signal',
    DC: 'Disconnected Number - Agent-Defined Disconnected number',
    ADC: 'Disconnected Number Auto - Carrier-received Disconnected number',
    ADCT: 'Congested Number Auto - Carrier-received Congestion(19,21,34,38)',
    DEC: 'Declined Sale - Agent-defined status',
    DNC: 'DO NOT CALL - If defined, lead will also go in the VICIDIAL DNC list',
    DNCL: 'DO NOT CALL Hopper Match - status of a lead that matches the phone number of a lead that has been placed in the VICIDIAL DNC list',
    DNCC: 'DO NOT CALL Hopper Match Campaign- status of a lead that matches the phone number of a lead that has been placed in the VICIDIAL Campaign-specific DNC list for this Campaign',
    SALE: 'Sale Made - Agent-defined status',
    N: 'No Answer - Agent-defined status',
    NI: 'Not Interested - Agent-defined status',
    NP: 'No Pitch No Price - Agent-defined status',
    PU: 'Call Picked Up - Status that a call is changed to as soon as the carrier has sent the Answer signal and before the call is sent on to an agent',
    PM: 'Played Message - outbound broadcast or SURVEY campaign status for when a message has been played to the customer',
    XFER: 'Call Transferred - Call has been sent from an agent to a Closer agent',
    ERI: 'Agent Error - An agent has closed their browser before dispositioning a lead',
    DONEM: 'Agent placed a manual dial phone call and then closed their browser before dispositioning a lead',
    SVYEXT: 'Survey sent to Extension - Survey outbound campaign status only',
    SVYVM: 'Survey sent to Voicemail - Survey outbound campaign status only',
    SVYHU: 'Survey Hungup - Survey outbound campaign status only',
    SVYREC: 'Survey sent to Record - Survey outbound campaign status only',
    HXFER: 'Hold Recall Transfer to another in-group',
    HOLDTO: 'Hold time option call termination on inbound call',
    QVMAIL: 'Queue Abandon Voicemail Left',
    RQXFER: 'Re-Queue Transfer back to agent',
    CPDATB: 'Sangoma Call Progress Detection CPD All-Trunks-Busy',
    CPDB: 'Sangoma Call Progress Detection CPD Busy',
    CPDNA: 'Sangoma Call Progress Detection CPD No-Answer',
    CPDREJ: 'Sangoma Call Progress Detection CPD Reject',
    CPDINV: 'Sangoma Call Progress Detection CPD Invalid-Number',
    CPDSUA: 'Sangoma Call Progress Detection CPD Service-Unavailable',
    CPDSI: 'Sangoma Call Progress Detection CPD Sit-Intercept',
    CPDSNC: 'Sangoma Call Progress Detection CPD Sit-No-Circuit',
    CPDSR: 'Sangoma Call Progress Detection CPD Sit-Reorder',
    CPDSUK: 'Sangoma Call Progress Detection CPD Sit-Unknown',
    CPDSV: 'Sangoma Call Progress Detection CPD Sit-Vacant',
    CPDUK: 'Sangoma Call Progress Detection CPD Unknown',
    CPDERR: 'Sangoma Call Progress Detection CPD Error',
    TIMEOT: 'Inbound call Drop Timeout, call waited until drop-seconds and was sent on to the drop action',
    AFTHRS: 'Inbound after hours drop, call received outside of in-group call time',
    NANQUE: 'Inbound no agent no queue drop, no agent logged in',
    RAXFER: 'Remote Agent API tran',
};
