export const baseUrl = process.env.REACT_APP_BASE_URL;

export const endpoints = {
    agents: '/agents/',
    reports: '/reports/',
    login: '/auth/login/',
    extensions: '/extensions/',
    stats: '/reports/get_stat/',
    refreshToken: '/auth/token/refresh/',
    verifyToken: '/auth/token/verify/',
    agentStart: '/agents/start/',
    agentStop: '/agents/stop/',
    billingNewSession: '/billing/new_session/',
    billingGetSubscription: '/billing/',
    billingGetProducts: '/billing/get_products/',
    billingGetInvoice: '/billing/get_invoice/',
    billingNewCustomerPortalSession: '/billing/create_customer_portal_session/',
    resetExtensionSecret: '/extensions/reset/',
    leadList: '/leadlists/',
    callRecordings: '/recordings/',
    reportById: '/reports/get_detail/?id=',
    uploads: '/uploads/',
    uploadStandardLeads: '/leads/upload/',
    leadsDefaultMapping: (id: string) => `/uploads/${id}/set_default_mapping/`,
    uploadsGetMappingHeaders: (id: string) =>
        `/uploads/${id}/get_mapping_headers/`,
    uploadsByListId: (id: string) => `/leadlists/${id}/uploads/`,
    leadsByListId: (id: string) => `/leadlists/${id}/leads/`,
    leadById: (id: string) => `/leads/${id}/`,
    leadSourceDataById: (id: string) => `/leads/${id}/source_data/`,
    leads: '/leads/',
    insertCustomLeads: '/leads/insert_custom/',
    campaigns: '/campaigns/',
    carriers: '/carriers/',
    callHours: '/hours/',
    supervisors: '/supervisors/',
    supervisorSendOtpById: (id: string) => `/supervisors/${id}/otp_send/`,
    supervisorVerifyOtpById: (id: string) => `/supervisors/${id}/otp_verify/`,
    profile: '/users/profile/',
    calls: '/calls/',
    bots: '/bots/',
    botById: (id: string) => `/bots/${id}/`,
    intents: '/intents/',
    intentsByBotId: (id: string) => `/bots/${id}/intents/`,
    intentById: (id: string) => `/intents/${id}/`,
    botScripts: '/botscripts/',
    botScriptsByBotId: (id: string) => `/bots/${id}/botscript/`,
    botGraphByBotId: (id: string) => `/bots/${id}/graph/`,
    botCompileByBotId: (id: string) => `/bots/${id}/compile/`,
    botChatByBotId: (id: string) => `/bots/${id}/chat/`,
    callerIdValidation: '/carriers/callerid_validation_request/',
    callerIdVerificationStatus: '/carriers/callerid_verification_status/',
    twilioCreateCallerId: '/carriers/create_twilio_carrier/',
    dialsenseCarrier: `/dialsensecarriers/`,
    activeCalls: '/calls/live/',
    bargin: (id: string) => `/calls/${id}/bargein/`,
    getPrefix: '/carriers/get_prefix/',
    getCustomerId: '/billing/get_subscription/',
    getSystemInfo: '/insights/sysinfo/',
    getAllOrgs: '/users/get_orgs/',
    getOrgTeams: '/organizations/team/',
    activeOrg: '/users/active_org/',
    organization: '/organizations/',
    inviteTeams: '/users/invite/',
    signup: '/users/signup/',
    resetPasswordRequest: '/users/reset-password-request/',
    resetPassword: '/users/reset-password/',
    activateAccount: '/users/activate/',
    getInvites: '/users/invites/',
    acceptOrgRequest: '/users/join-org/',
    getOrgInviteDetails: (id: string) => `/users/invite-details/${id}/`,
    addAdmin: '/organizations/add_admin/',
    removeAdmin: '/organizations/remove_admin/',
    removeTeamMembers: '/organizations/remove_member/',
    apikeys: '/apikey/',

};
