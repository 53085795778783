export const permissions = {
    dashboard: {
        superadmin: true,
        orgadmin: true,
        member: true,
        float: true,
        plansCard: {
            superadmin: true,
            orgadmin: true,
            member: false,
            float: false,
        },
    },
    agents: {
        float: false, //setting true
        superadmin: true,
        member: true,
        orgadmin: true,
        phones: {
            superadmin: true,
            member: false,
            orgadmin: false,
            create: {
                superadmin: true,
                member: false,
                orgadmin: false,
            },
            read: {
                superadmin: true,
                member: false,
                orgadmin: false,
            },
            delete: { superadmin: true, member: false, orgadmin: false },
            update: { superadmin: true, member: false, orgadmin: false },
        },
        ai_agents: {
            superadmin: true,
            member: false,
            orgadmin: false,
            create: {
                superadmin: false,
                member: false,
                orgadmin: false,
            },
            read: {
                superadmin: true,
                member: false,
                orgadmin: true,
            },
            update: {
                superadmin: false,
                member: false,
                orgadmin: false,
            },
            delete: {
                superadmin: false,
                member: false,
                orgadmin: false,
            },
            start: {
                superadmin: true,
                member: false,
                orgadmin: true,
            },
            stop: {
                superadmin: true,
                member: false,
                orgadmin: true,
            },
        },
        bots: {
            superadmin: true,
            member: false,
            orgadmin: true,
            create: {
                superadmin: true,
                member: false,
                orgadmin: true,
            },
            read: {
                superadmin: true,
                member: true,
                orgadmin: true,
            },
            update: {
                superadmin: true,
                member: false,
                orgadmin: true,
            },
            delete: {
                superadmin: true,
                member: false,
                orgadmin: false,
            },
        },
        active_calls: {
            superadmin: true,
            member: true,
            orgadmin: true,
            create: {
                superadmin: true,
                member: false,
                orgadmin: false,
            },
            read: {
                superadmin: true,
                member: true,
                orgadmin: true,
            },
            update: {
                superadmin: true,
                member: false,
                orgadmin: false,
            },
            delete: {
                superadmin: true,
                member: false,
                orgadmin: false,
            },
        },
    },
    campaign: {
        float: false,
        superadmin: true,
        member: true,
        orgadmin: true,
        list_campaign: {
            superadmin: true,
            member: true,
            orgadmin: true,
            create: {
                superadmin: true,
                member: false,
                orgadmin: true,
            },
            supervisorButton: {
                superadmin: true,
                member: false,
                orgadmin: true,
            },
            read: {
                superadmin: true,
                member: true,
                orgadmin: true,
            },
            update: {
                superadmin: true,
                member: false,
                orgadmin: true,
            },
            delete: {
                superadmin: true,
                member: false,
                orgadmin: true,
            },
            export: {
                superadmin: true,
                member: true,
                orgadmin: true,
            },
        },
        add_campaign: {
            superadmin: true,
            orgadmin: true,
            member: false,
        },
    },
    lists: {
        float: false,
        superadmin: true,
        member: true,
        orgadmin: true,
        show_lists: {
            superadmin: true,
            member: true,
            orgadmin: true,
            create: {
                superadmin: true,
                member: false,
                orgadmin: true,
            },
            read: {
                superadmin: true,
                member: true,
                orgadmin: true,
            },
            update: {
                superadmin: true,
                member: false,
                orgadmin: false,
            },
            delete: {
                superadmin: true,
                member: false,
                orgadmin: false,
            },
        },
        add_lists: {
            superadmin: false,
            member: true,
            orgadmin: true,
            create: {
                superadmin: true,
                member: true,
                orgadmin: true,
            },
            read: {
                superadmin: true,
                member: true,
                orgadmin: true,
            },
            update: {
                superadmin: true,
                member: false,
                orgadmin: true,
            },
            delete: {
                superadmin: true,
                member: false,
                orgadmin: true,
            },
        },
        import_leads: {
            superadmin: true,
            member: true,
            orgadmin: true,
            create: {
                superadmin: true,
                member: true,
                orgadmin: true,
            },
            read: {
                superadmin: true,
                member: true,
                orgadmin: true,
            },
            update: {
                superadmin: true,
                member: false,
                orgadmin: true,
            },
            delete: {
                superadmin: true,
                member: false,
                orgadmin: true,
            },
        },
    },
    callHistory: {
        float: false,
        superadmin: true,
        member: true,
        orgadmin: true,
        basic: {
            superadmin: true,
            member: true,
            orgadmin: true,
            create: {
                superadmin: true,
                member: false,
                orgadmin: true,
            },
            read: {
                superadmin: true,
                member: true,
                orgadmin: true,
            },
            update: {
                superadmin: true,
                member: false,
                orgadmin: true,
            },
            delete: {
                superadmin: true,
                member: false,
                orgadmin: true,
            },
            download: {
                superadmin: true,
                member: true,
                orgadmin: true,
            },
        },
        advanced: {
            superadmin: true,
            orgadmin: false,
            member: false,
        },
    },
    settings: {
        float: false,
        superadmin: true,
        member: true,
        orgadmin: true,
        call_hours: {
            superadmin: true,
            member: true,
            orgadmin: true,
            create: {
                superadmin: true,
                member: false,
                orgadmin: true,
            },
            read: {
                superadmin: true,
                member: true,
                orgadmin: true,
            },
            update: {
                superadmin: true,
                member: false,
                orgadmin: true,
            },
            delete: {
                superadmin: true,
                member: false,
                orgadmin: true,
            },
            download: {
                superadmin: true,
                member: true,
                orgadmin: true,
            },
        },
        carriers: {
            float: false,
            superadmin: true,
            orgadmin: true,
            member: false,
            create: {
                superadmin: true,
                member: false,
                orgadmin: true,
            },
            read: {
                superadmin: true,
                member: true,
                orgadmin: true,
            },
            update: {
                superadmin: true,
                member: false,
                orgadmin: true,
            },
            delete: {
                superadmin: true,
                member: false,
                orgadmin: true,
            },
            download: {
                superadmin: true,
                member: true,
                orgadmin: true,
            },
        },
        profile: {
            superadmin: true,
            orgadmin: true,
            member: true,
            create: {
                superadmin: true,
                member: false,
                orgadmin: true,
            },
            read: {
                superadmin: true,
                member: true,
                orgadmin: true,
            },
            update: {
                superadmin: true,
                member: false,
                orgadmin: true,
            },
            delete: {
                superadmin: true,
                member: false,
                orgadmin: true,
            },
            download: {
                superadmin: true,
                member: true,
                orgadmin: true,
            },
        },
        teams: {
            float: false,
            superadmin: true,
            orgadmin: true,
            member: false,
            create: {
                superadmin: true,
                member: false,
                orgadmin: true,
            },
            read: {
                superadmin: true,
                member: true,
                orgadmin: true,
            },
            update: {
                superadmin: true,
                member: false,
                orgadmin: true,
            },
            delete: {
                superadmin: true,
                member: false,
                orgadmin: true,
            },
            download: {
                superadmin: true,
                member: true,
                orgadmin: true,
            },
        },
        apikeys: {
            float: false,
            superadmin: true,
            orgadmin: true,
            member: false,
            create: {
                superadmin: true,
                member: false,
                orgadmin: true,
            },
            read: {
                superadmin: true,
                member: true,
                orgadmin: true,
            },
            update: {
                superadmin: true,
                member: false,
                orgadmin: true,
            },
            delete: {
                superadmin: true,
                member: false,
                orgadmin: true,
            },
            download: {
                superadmin: true,
                member: true,
                orgadmin: true,
            },
        },
        supervisors: {
            float: false,
            superadmin: true,
            orgadmin: true,
            member: false,
            create: {
                superadmin: true,
                member: false,
                orgadmin: true,
            },
            read: {
                superadmin: true,
                member: true,
                orgadmin: true,
            },
            update: {
                superadmin: true,
                member: false,
                orgadmin: true,
            },
            delete: {
                superadmin: true,
                member: false,
                orgadmin: true,
            },
            download: {
                superadmin: true,
                member: true,
                orgadmin: true,
            },
            verify: {
                superadmin: true,
                orgadmin: true,
            }
        },
    },
    billing: {
        float: false,
        superadmin: true,
        member: false,
        orgadmin: true,
        plans: {
            superadmin: true,
            member: true,
            orgadmin: true,
            create: {
                superadmin: true,
                member: false,
                orgadmin: true,
            },
            read: {
                superadmin: true,
                member: true,
                orgadmin: true,
            },
            update: {
                superadmin: true,
                member: false,
                orgadmin: true,
            },
            delete: {
                superadmin: true,
                member: false,
                orgadmin: true,
            },
            export: {
                superadmin: true,
                member: true,
                orgadmin: true,
            },
        },
        current_plan: {
            superadmin: true,
            member: true,
            orgadmin: true,
            create: {
                superadmin: true,
                member: false,
                orgadmin: true,
            },
            read: {
                superadmin: true,
                member: true,
                orgadmin: true,
            },
            update: {
                superadmin: true,
                member: false,
                orgadmin: true,
            },
            delete: {
                superadmin: true,
                member: false,
                orgadmin: true,
            },
            export: {
                superadmin: true,
                member: true,
                orgadmin: true,
            },
        },
    },
};
