import React, { useEffect, useRef } from 'react';
import { isMobile } from 'react-device-detect';
import { useSidebarContext } from '../../contexts/SidebarContext';
import { Navbar } from '../NavBar';
import { Sidebar } from '../Sidebar/Sidebar';

interface Props {
    children: React.ReactNode;
}

export const MainLayout: React.FC<Props> = ({ children }) => {
    const { sidebarActive, setSidebarActive } = useSidebarContext();
    const sidebarContainerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (sidebarContainerRef.current) {
            if (sidebarActive) {
                sidebarContainerRef.current.style.transition = 'transform 0.3s';
                sidebarContainerRef.current.style.transform = 'translateX(0%)';
            } else {
                sidebarContainerRef.current.style.transition = 'transform 0.3s';
                sidebarContainerRef.current.style.transform =
                    'translateX(-100%)';
            }
        }
    }, [sidebarActive]);

    useEffect(() => {
        if (isMobile) {
            setSidebarActive(false);
        }
    }, [isMobile, setSidebarActive]);

    return (
        <div className="min-h-screen bg-gray-100">
            <>
                <div
                    className="w-full bg-white flex flex-1 items-center px-4 shadow-small"
                    style={{ height: '5vh' }}
                >
                    <Navbar isLoggedIn />
                </div>
                <div
                    className="flex flex-1 overflow-x-hidden"
                    style={{ height: '95vh' }}
                >
                    <div ref={sidebarContainerRef}>
                        {sidebarActive && <Sidebar />}
                    </div>
                    <div className="flex flex-col flex-1 h-full overflow-y-hidden overflow-x-auto transition-all">
                        {children}
                    </div>
                </div>
            </>
        </div>
    );
};
