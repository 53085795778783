import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { BiChevronDown, BiChevronUp } from 'react-icons/bi';
import { FaHistory } from 'react-icons/fa';
import {
    MdCampaign,
    MdHome,
    MdOutlineViewList,
    MdReceipt,
    MdSupportAgent
} from 'react-icons/md';
import { useHistory, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { useAuthContext } from '../../contexts/AuthContext';
import { checkPermission } from '../../shared/authHelper';
import { theme } from '../../shared/constants';
import { httpConfig } from '../../shared/localStorageHelper';
import { pbxAxiosInstance } from '../../shared/requests';
import { endpoints } from '../../shared/urls';
import { Settings } from '../Svgs';
import { H5, Label, PX1 } from '../Typography';


interface Props { }

const routes = {
    DASHBOARD: '/',
    AGENTS: '/agents',
    CAMPAIGN: '/campaigns',
    LISTS: '/lists',
    CALL_HISTORY: '/call-history',
    SETTINGS: '/settings',
    BILLING: '/billing',
};

const AgentsSubMenu = [
    {
        id: 'agents.phones',
        label: 'Phones',
        parent: routes.AGENTS,
        route: '/phones',
        domId: 'phones',
    },
    {
        id: 'agents.ai_agents',
        label: 'AI Agents',
        parent: routes.AGENTS,
        route: '/ai-agents',
        domId: 'ai-agents',
    },
    {
        id: 'agents.bots',
        label: 'Bots',
        parent: routes.AGENTS,
        route: '/bots',
        domId: 'bots',
    },
    {
        id: 'agents.active_calls',
        label: 'Active Calls',
        parent: routes.AGENTS,
        route: '/active-calls',
        domId: 'active-calls',
    },
];

const CampaignSubMenu = [
    {
        id: 'campaign.list_campaign',
        label: 'List Campaign',
        parent: routes.CAMPAIGN,
        route: '/',
        domId: 'list-campaign',
    },
    {
        id: 'campaign.add_campaign',
        label: 'Add Campaign',
        parent: routes.CAMPAIGN,
        route: '/add',
        domId: 'add-campaign',
    },
];

const ListsSubMenu = [
    {
        id: 'lists.show_lists',
        label: 'Show lists',
        parent: routes.LISTS,
        route: '/',
        domId: 'show-lists',
    },
    {
        id: 'lists.add_lists',
        label: 'Add list',
        parent: routes.LISTS,
        route: '/add',
        domId: 'add-list',
    },
    {
        id: 'lists.import_leads',
        label: 'Import leads',
        parent: routes.LISTS,
        route: '/add-leads',
        domId: 'import-leads',
    },
];

const CallHistorySubMenu = [
    {
        id: 'callHistory.basic',
        label: 'Basic',
        parent: routes.CALL_HISTORY,
        route: '/basic',
        domId: 'basic',
    },
    {
        id: 'callHistory.advanced',
        label: 'Advanced',
        parent: routes.CALL_HISTORY,
        route: '/advanced',
        domId: 'advanced',
    },
];

const SettingsSubMenu = [
    {
        id: 'settings.call_hours',
        label: 'Call Hours',
        parent: routes.SETTINGS,
        route: '/call-hours',
        domId: 'call-hours',
    },
    {
        id: 'settings.carriers',
        label: 'Carriers',
        parent: routes.SETTINGS,
        route: '/carriers',
        domId: 'carriers',
    },
    {
        id: 'settings.teams',
        label: 'Teams',
        parent: routes.SETTINGS,
        route: '/teams',
        domId: 'teams',
    },
    {
        id: 'settings.apikeys',
        label: 'API Keys',
        parent: routes.SETTINGS,
        route: '/api-keys',
        domId: 'apikeys',
    },
    {
        id: 'settings.supervisors',
        label: 'Supervisors',
        parent: routes.SETTINGS,
        route: '/supervisors',
        domId: 'supervisors',
    },
];

const BillingSubMenu = [
    {
        id: 'billing.plans',
        label: 'Plans',
        parent: routes.BILLING,
        route: '/plans',
        domId: 'plan',
    },
    {
        id: 'billing.current_plan',
        label: 'Manage',
        parent: routes.BILLING,
        route: '',
        domId: 'currentplan',
    },
];

export const Sidebar: React.FC<Props> = () => {
    const history = useHistory();
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const sidebarRef = useRef<HTMLDivElement>(null);
    const [ConditionalAgentsSubMenu, setConditionalAgentsSubMenu] =
        useState<any>([]);
    const [ConditionalCampaignSubMenu, setConditionalCampaignSubMenu] =
        useState<any>([]);
    const [ConditionalListsSubMenu, setConditionalListsSubMenu] = useState<any>(
        [],
    );
    const [ConditionalCallHistorySubMenu, setConditionalCallHistorySubMenu] =
        useState<any>([]);
    const [ConditionalSettingsSubMenu, setConditionalSettingsSubMenu] =
        useState<any>([]);
    const [ConditionalBillingSubMenu, setConditionalBillingSubMenu] =
        useState<any>([]);
    const [subcriptionError, setSubcriptionError] = useState(false);
    const [didMount, setDidMount] = useState(false);
    const [subscription, setSubscription] = useState<any>(null);
    const [pathname, setPathname] = useState(location.pathname);
    const [activeSubMenu, setActiveSubMenu] = useState('');
    const { user } = useAuthContext();
    const userData = user ? JSON.parse(user) : '';
    const active_role = localStorage.getItem('active_role');
    useEffect(() => {
        setPathname(location.pathname);
        Object.entries(routes).forEach(routeElement => {
            const route = routeElement[1];
            if (location.pathname.includes(route)) {
                setActiveSubMenu(route);
            }
        });
    }, [history, location.pathname]);

    const getCustomerSubscription = async (url: string) => {
        setLoading(true);
        const config = await httpConfig();
        const data = await pbxAxiosInstance({
            url,
            headers: config.headers,
            method: 'GET',
        })
            .then(response => {
                setSubscription(response.data);
            })
            .catch(function (error) {
                if (error.response?.status == 403) {
                    setSubcriptionError(true);
                }
            });
        setLoading(false);
    };

    useEffect(() => {
        (async () => {
            await getCustomerSubscription(
                `${endpoints.billingGetSubscription}`,
            );
        })();
        setDidMount(true);
        return () => setDidMount(false);
    }, []);

    useEffect(() => {
        const tempAgentsSubMenu: {
            id: string;
            label: string;
            parent: string;
            route: string;
            domId: string;
        }[] = [];
        AgentsSubMenu.map(a => {
            if (checkPermission(a.id, active_role)) {
                tempAgentsSubMenu.push(a);
            }
        });
        setConditionalAgentsSubMenu(tempAgentsSubMenu);

        const tempCampaignSubMenu: {
            id: string;
            label: string;
            parent: string;
            route: string;
            domId: string;
        }[] = [];
        CampaignSubMenu.map(a => {
            if (checkPermission(a.id, active_role)) {
                tempCampaignSubMenu.push(a);
            }
        });
        setConditionalCampaignSubMenu(tempCampaignSubMenu);

        const tempListsSubMenu: {
            id: string;
            label: string;
            parent: string;
            route: string;
            domId: string;
        }[] = [];
        ListsSubMenu.map(a => {
            if (checkPermission(a.id, active_role)) {
                tempListsSubMenu.push(a);
            }
        });
        setConditionalListsSubMenu(tempListsSubMenu);

        const tempCallHistorySubMenu: {
            id: string;
            label: string;
            parent: string;
            route: string;
            domId: string;
        }[] = [];
        CallHistorySubMenu.map(a => {
            if (checkPermission(a.id, active_role)) {
                tempCallHistorySubMenu.push(a);
            }
        });
        setConditionalCallHistorySubMenu(tempCallHistorySubMenu);

        const tempSettingsSubMenu: {
            id: string;
            label: string;
            parent: string;
            route: string;
            domId: string;
        }[] = [];
        SettingsSubMenu.map(a => {
            if (checkPermission(a.id, active_role)) {
                tempSettingsSubMenu.push(a);
            }
        });
        setConditionalSettingsSubMenu(tempSettingsSubMenu);
    }, []);

    useEffect(() => {
        const tempBillingSubMenu: {
            id: string;
            label: string;
            parent: string;
            route: string;
            domId: string;
        }[] = [];
        BillingSubMenu.map(a => {
            if (checkPermission(a.id, active_role)) {
                if (subcriptionError && a.label === 'Plans') {
                    tempBillingSubMenu.push(a);
                } else if (!subcriptionError && a.label != 'Plans') {
                    tempBillingSubMenu.push(a);
                }

            }
        });
        setConditionalBillingSubMenu(tempBillingSubMenu);
    }, [subscription]);

    if (!didMount) {
        return null;
    }
    const selectedOrgPlan = localStorage.getItem('selectedOrgPlan');

    return (
        <div
            ref={sidebarRef}
            id="sidebar"
            className="w-64 h-full shadow-small p-4 pb-8 flex flex-col justify-between overflow-y-auto bg-gray-800"
        >
            <div>
                {checkPermission('dashboard', active_role) ? (
                    <SidebarLink
                        active={pathname === routes.DASHBOARD}
                        label="Dashboard"
                        id="dashboard"
                        route={routes.DASHBOARD}
                        Icon={
                            <MdHome
                                size={24}
                                color={
                                    pathname.startsWith(routes.DASHBOARD)
                                        ? theme.white
                                        : theme.darkBlue
                                }
                            />
                        }
                    />
                ) : (
                    <></>
                )}

                {checkPermission('agents', active_role) &&
                    selectedOrgPlan !== 'free' ? (
                    <SidebarLink
                        active={pathname.startsWith('/agents')}
                        label="Agents"
                        id="agents"
                        route={
                            routes.AGENTS + ConditionalAgentsSubMenu[0]?.route
                        }
                        subMenus={
                            ConditionalAgentsSubMenu.length > 0
                                ? ConditionalAgentsSubMenu
                                : ''
                        }
                        activeSubMenu={activeSubMenu}
                        setActiveSubMenu={setActiveSubMenu}
                        Icon={
                            <MdSupportAgent
                                size={24}
                                color={
                                    pathname.startsWith(routes.AGENTS)
                                        ? theme.white
                                        : theme.gray888
                                }
                            />
                        }
                    />
                ) : (
                    <></>
                )}

                {checkPermission('campaign', active_role) &&
                    selectedOrgPlan !== 'free' ? (
                    <SidebarLink
                        active={pathname.startsWith(routes.CAMPAIGN)}
                        label={'Campaigns'}
                        id="campaigns"
                        route={
                            routes.CAMPAIGN +
                            ConditionalCampaignSubMenu[0]?.route
                        }
                        activeSubMenu={activeSubMenu}
                        subMenus={
                            ConditionalCampaignSubMenu.length > 0
                                ? ConditionalCampaignSubMenu
                                : ''
                        }
                        setActiveSubMenu={setActiveSubMenu}
                        Icon={
                            <MdCampaign
                                size={22}
                                color={
                                    pathname.startsWith(routes.CAMPAIGN)
                                        ? theme.white
                                        : theme.gray888
                                }
                            />
                        }
                    />
                ) : (
                    <></>
                )}

                {checkPermission('lists', active_role) &&
                    selectedOrgPlan !== 'free' ? (
                    <SidebarLink
                        active={pathname.startsWith(routes.LISTS)}
                        label={'Lists'}
                        id="lists"
                        route={routes.LISTS + ConditionalListsSubMenu[0]?.route}
                        activeSubMenu={activeSubMenu}
                        subMenus={
                            ConditionalListsSubMenu.length > 0
                                ? ConditionalListsSubMenu
                                : ''
                        }
                        setActiveSubMenu={setActiveSubMenu}
                        Icon={
                            <MdOutlineViewList
                                size={22}
                                color={
                                    pathname.startsWith(routes.LISTS)
                                        ? theme.white
                                        : theme.gray888
                                }
                            />
                        }
                    />
                ) : (
                    <></>
                )}

                {checkPermission('callHistory', active_role) &&
                    selectedOrgPlan !== 'free' ? (
                    <SidebarLink
                        active={pathname.startsWith(routes.CALL_HISTORY)}
                        label={'Call History'}
                        id="call-history"
                        route={
                            routes.CALL_HISTORY +
                            ConditionalCallHistorySubMenu[0]?.route
                        }
                        activeSubMenu={activeSubMenu}
                        subMenus={
                            ConditionalCallHistorySubMenu.length > 0
                                ? ConditionalCallHistorySubMenu
                                : ''
                        }
                        setActiveSubMenu={setActiveSubMenu}
                        Icon={
                            <FaHistory
                                height={18}
                                width={18}
                                color={
                                    pathname.startsWith(routes.CALL_HISTORY)
                                        ? theme.white
                                        : theme.gray888
                                }
                            />
                        }
                    />
                ) : (
                    <></>
                )}

                {checkPermission('settings', active_role) &&
                    selectedOrgPlan !== 'free' ? (
                    <SidebarLink
                        active={pathname.startsWith(routes.SETTINGS)}
                        label={'Settings'}
                        id="settings"
                        route={
                            routes.SETTINGS +
                            ConditionalSettingsSubMenu[0]?.route
                        }
                        activeSubMenu={activeSubMenu}
                        subMenus={
                            ConditionalSettingsSubMenu.length > 0
                                ? ConditionalSettingsSubMenu
                                : ''
                        }
                        setActiveSubMenu={setActiveSubMenu}
                        Icon={
                            <Settings
                                height={18}
                                width={18}
                                color={
                                    pathname.startsWith(routes.SETTINGS)
                                        ? theme.white
                                        : theme.gray888
                                }
                            />
                        }
                    />
                ) : (
                    <></>
                )}
                {checkPermission('billing', active_role) &&
                    selectedOrgPlan !== 'free' ? (
                    <SidebarLink
                        active={pathname.startsWith(routes.BILLING)}
                        label={'Billing'}
                        id="billing"
                        route={
                            routes.BILLING + ConditionalBillingSubMenu[0]?.route
                        }
                        activeSubMenu={activeSubMenu}
                        subMenus={
                            ConditionalBillingSubMenu.length > 0
                                ? ConditionalBillingSubMenu
                                : ''
                        }
                        setActiveSubMenu={setActiveSubMenu}
                        Icon={
                            <MdReceipt
                                size="20px"
                                color={
                                    pathname.startsWith(routes.BILLING)
                                        ? theme.white
                                        : theme.gray888
                                }
                            />
                        }
                    />
                ) : (
                    <></>
                )}
            </div>

            <span>
                {active_role === 'orgadmin' && (selectedOrgPlan === "basic" || selectedOrgPlan === "pro") ? (
                    <Link
                        to="/billing"
                        className="block p-4 max-w-sm bg-brand-l4 rounded-lg border border-gray-200 shadow-md hover:bg-gray-100"
                    >
                        {
                            selectedOrgPlan === "basic" ? (
                                <>
                                    <p className="text-xs text-gray-700 dark:text-gray-400 mb-2">
                                        Run more parallel agents, get your dedicated
                                        deployment, unlimited data archive and more.
                                    </p>
                                    <button className="text-xs px-3 py-2 rounded hover:shadow-md border-gray font-medium bg-gray-200 hover:bg-brand-l3 ">
                                        Upgrade to Pro
                                    </button>
                                </>) : (selectedOrgPlan === "pro" ? (
                                    <>
                                        <p className="text-xs text-gray-700 dark:text-gray-400 mb-2">
                                            Push all limits and get a plan that is made specially for you.
                                        </p>
                                        <button className="text-xs px-3 py-2 rounded hover:shadow-md border-gray font-medium bg-gray-200 hover:bg-brand-l3 ">
                                            Upgrade to Enterprise
                                        </button>
                                    </>
                                ) : (<></>)
                            )
                        }

                    </Link>
                ) : (
                    <></>
                )}

                <div>
                    <div className="w-full mb-4"></div>
                </div>
                <a href="https://dynopii.com" target="_blank" rel="noreferrer">
                    <Label
                        fontWeight="medium"
                        center
                        colorClass="text-white hover:underline"
                    >{`© ${new Date().getFullYear()} Dynopii, Inc.`}</Label>
                </a>
            </span>
        </div>
    );
};

type SubMenuItem = {
    label: string;
    id: string;
    route: string;
    parent: string;
    domId: string;
};

interface ISidebarLink {
    // onClick: () => void;
    active: boolean;
    label: string;
    Icon: React.ReactNode;
    subMenus?: SubMenuItem[];
    setActiveSubMenu?: React.Dispatch<React.SetStateAction<string>>;
    route: string;
    activeSubMenu?: string;
    id: string;
}

export const SidebarLink: React.FC<ISidebarLink> = ({
    active,
    label,
    Icon,
    subMenus,
    setActiveSubMenu,
    route,
    activeSubMenu,
    id,
}) => {
    const subMenusRef = useRef<HTMLDivElement>(null);

    useLayoutEffect(() => {
        if (null !== subMenusRef.current && subMenus) {
            subMenusRef.current.style.maxHeight =
                activeSubMenu === subMenus[0].parent
                    ? `${subMenusRef.current.scrollHeight}px`
                    : '0px';
            subMenusRef.current.style.opacity =
                activeSubMenu === subMenus[0].parent ? '1' : '0';
            subMenusRef.current.style.visibility =
                activeSubMenu === subMenus[0].parent ? 'visible' : 'hidden';
        }
    }, [subMenusRef, active, activeSubMenu, route, subMenus]);
    return (
        <>
            <div
                id={id}
                className={`z-10 ${active ? 'bg-gray-900' : 'text-gray-400 hover:bg-gray-700 hover:!text-gray-100'
                    } rounded-none flex cursor-pointer`}
            >
                <Link className="w-full" to={route}>
                    <div className="p-2 flex items-center flex-1">
                        {Icon}
                        <div className="ml-3">
                            <H5
                                colorClass={
                                    active ? 'text-white' : ''
                                }
                            >
                                {label}
                            </H5>
                        </div>
                    </div>
                </Link>
                {subMenus && setActiveSubMenu && (
                    <div
                        className="p-1"
                        onClick={() =>
                            activeSubMenu === subMenus[0].parent
                                ? setActiveSubMenu('')
                                : setActiveSubMenu(subMenus[0].parent)
                        }
                    >
                        {activeSubMenu === subMenus[0].parent ? (
                            <BiChevronUp
                                size={30}
                                className={
                                    active ? 'text-white' : 'text-white'
                                }
                            />
                        ) : (
                            <div>
                                <BiChevronDown
                                    size={30}
                                    className={
                                        active ? 'text-gray-500' : 'text-gray-500'
                                    }
                                />
                            </div>
                        )}
                    </div>
                )}
            </div>
            {subMenus && (
                <div ref={subMenusRef} style={{ transition: 'all 0.2s' }}>
                    {/* {active && */}
                    {subMenus.map(item => (
                        <SideBarSubMenuItem key={item.id} {...item} />
                    ))}
                </div>
            )}
        </>
    );
};

interface ISideBarSubMenuItem {
    route: string;
    label: string;
    id: string;
    parent: string;
    domId: string;
}

export const SideBarSubMenuItem: React.FC<ISideBarSubMenuItem> = ({
    route,
    label,
    parent,
    domId,
}) => {
    const location = useLocation();
    const [active, setActive] = useState(false);

    useEffect(() => {
        const excludedLinks = ['/campaigns', '/lists'];

        if (!excludedLinks.includes(parent)) {
            setActive(location.pathname.includes(parent + route));
        } else {
            setActive(location.pathname === parent + route);
        }
    }, [location.pathname, parent, route]);

    if (route === '/advanced' && isMobile) {
        return null;
    }

    return (
        <Link id={domId} to={parent + route}>
            <div
                className={`ml-6  transition-all ${active
                    ? 'border-l-4 border-blue-500 bg-gray-900'
                    : 'border-l-4 border-gray-700 bg-gray-800 hover:bg-gray-900 text-white duration-200 rounded-tr-default rounded-br-default'
                    } py-2 px-3 cursor-pointer`}
            >
                <PX1
                    fontWeight="medium"
                    colorClass={active ? 'text-white' : `text-gray-400 hover:text-gray-300 `}
                >
                    {label}
                </PX1>
            </div>
        </Link>
    );
};
