import React from 'react';

interface Props {
    children: string | number | boolean | undefined;
    colorClass?: string;
    center?: boolean;
    fontWeight?:
        | 'thin'
        | 'extralight'
        | 'light'
        | 'normal'
        | 'medium'
        | 'semibold'
        | 'bold'
        | 'extrabold'
        | 'black';
}

interface ITypographyFactoryProps extends Props {
    tagName: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p';
    typographyClass: string;
}

export const TypographyFactory: React.FC<ITypographyFactoryProps> = ({
    tagName,
    center,
    fontWeight,
    children,
    colorClass,
    typographyClass,
}) => {
    const fontWeightClasses = fontWeight ? `font-${fontWeight}` : 'font-normal';
    const colorClasses = colorClass || 'text-gray-111';
    const centerClass = center ? 'text-center' : '';
    const classes = `${typographyClass} ${fontWeightClasses} ${colorClasses} ${centerClass}`;
    const element = React.createElement(
        tagName,
        { className: classes },
        children,
    );
    return element;
};

export const H1: React.FC<Props> = props => (
    <TypographyFactory
        typographyClass="text-h3 sm:text-h2 md:text-h1"
        tagName="h1"
        {...props}
    />
);

export const H2: React.FC<Props> = props => (
    <TypographyFactory typographyClass="text-h2" tagName="h2" {...props} />
);
export const H3: React.FC<Props> = props => (
    <TypographyFactory typographyClass="text-h3" tagName="h3" {...props} />
);
export const H4: React.FC<Props> = props => (
    <TypographyFactory typographyClass="text-h4" tagName="h4" {...props} />
);
export const H5: React.FC<Props> = props => (
    <TypographyFactory typographyClass="text-h5" tagName="h5" {...props} />
);
export const H6: React.FC<Props> = props => (
    <TypographyFactory typographyClass="text-h6" tagName="h6" {...props} />
);
export const PX1: React.FC<Props> = props => (
    <TypographyFactory typographyClass="text-px1" tagName="p" {...props} />
);
export const PX2: React.FC<Props> = props => (
    <TypographyFactory typographyClass="text-px2" tagName="p" {...props} />
);
export const PX3: React.FC<Props> = props => (
    <TypographyFactory typographyClass="text-px3" tagName="p" {...props} />
);
export const Label: React.FC<Props> = props => (
    <TypographyFactory typographyClass="text-label" tagName="p" {...props} />
);
