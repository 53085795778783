import React, { lazy } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { useLocation } from 'react-router-dom';
import { useAuthContext } from '../contexts/AuthContext';
import { checkPermission } from '../shared/authHelper';
import { MainLayout } from './layout/MainLayout';
import { withSuspense } from './withSuspense';

const Dashboard = lazy(() =>
    import('../pages/Dashboard').then(module => ({
        default: module.Dashboard,
    })),
);

const FloatDashboard = lazy(() =>
    import('../pages/FloatDashboard').then(module => ({
        default: module.FloatDashboard,
    })),
);

const PhoneById = lazy(() =>
    import('../pages/agents/Phones/PhoneById').then(module => ({
        default: module.PhoneById,
    })),
);

const AgentById = lazy(() =>
    import('../pages/agents/Agents/AgentById').then(module => ({
        default: module.AgentById,
    })),
);

const ListById = lazy(() =>
    import('../pages/lists/Lists/ListById').then(module => ({
        default: module.ListById,
    })),
);
const ActiveCalls = lazy(() =>
    import('../pages/agents/ActiveCalls').then(module => ({
        default: module.ActiveCalls,
    })),
);

const Login = lazy(() =>
    import('../pages/Login').then(module => ({ default: module.Login })),
);

const Signup = lazy(() =>
    import('../pages/SignUp').then(module => ({ default: module.SignUp })),
);

const ResetPassword = lazy(() =>
    import('../pages/ResetPassword').then(module => ({
        default: module.ResetPassword,
    })),
);

const CallRecording = lazy(() =>
    import('../pages/callHistory/CallRecording').then(module => ({
        default: module.CallRecording,
    })),
);
const Campaigns = lazy(() =>
    import('../pages/campaigns/Campaigns').then(module => ({
        default: module.Campaigns,
    })),
);

const CampaignById = lazy(() =>
    import('../pages/campaigns/Campaigns/CampaignById').then(module => ({
        default: module.CampaignById,
    })),
);
const CampaignsQueueMembers = lazy(() =>
    import('../pages/campaigns/Campaigns/CampaignQueueMembers').then(
        module => ({
            default: module.CampaignsQueueMembers,
        }),
    ),
);

const Carriers = lazy(() =>
    import('../pages/settings/Carriers').then(module => ({
        default: module.Carriers,
    })),
);

const CarrierById = lazy(() =>
    import('../pages/settings/Carriers/CarrierById').then(module => ({
        default: module.CarrierById,
    })),
);

const AddCarrier = lazy(() =>
    import('../pages/settings/Carriers/AddCarrier').then(module => ({
        default: module.AddCarrier,
    })),
);

const AddCustomCarrier = lazy(() =>
    import('../pages/settings/Carriers/AddCustomCarrier').then(module => ({
        default: module.AddCustomCarrier,
    })),
);

const AddDialSenseCarrier = lazy(() =>
    import('../pages/settings/Carriers/AddDialSenseCarrier').then(module => ({
        default: module.AddDialSenseCarrier,
    })),
);

const ModifyCarrier = lazy(() =>
    import('../pages/settings/Carriers/ModifyCarrier').then(module => ({
        default: module.ModifyCarrier,
    })),
);

const Lists = lazy(() =>
    import('../pages/lists/Lists').then(module => ({
        default: module.Lists,
    })),
);

const AddList = lazy(() =>
    import('../pages/lists/Lists/AddList').then(module => ({
        default: module.AddList,
    })),
);

const ModifyList = lazy(() =>
    import('../pages/lists/Lists/ModifyList').then(module => ({
        default: module.ModifyList,
    })),
);

const AddMappingForFile = lazy(() =>
    import('../pages/lists/Lists/AddMappingForFile').then(module => ({
        default: module.AddMappingForFile,
    })),
);

const Profile = lazy(() =>
    import('../pages/settings/Profile').then(module => ({
        default: module.Profile,
    })),
);

const Phones = lazy(() =>
    import('../pages/agents/Phones').then(module => ({
        default: module.Phones,
    })),
);

const Agents = lazy(() =>
    import('../pages/agents/Agents').then(module => ({
        default: module.Agents,
    })),
);

const CdrReports = lazy(() =>
    import('../pages/callHistory/CdrReports').then(module => ({
        default: module.CdrReports,
    })),
);

const CdrReportById = lazy(() =>
    import('../pages/callHistory/CdrReport/CdrReportById').then(module => ({
        default: module.CdrReportById,
    })),
);

const AddPhone = lazy(() =>
    import('../pages/agents/Phones/AddPhone').then(module => ({
        default: module.AddPhone,
    })),
);

const CallRecordingById = lazy(() =>
    import('../pages/callHistory/CallRecording/CallRecordingById').then(
        module => ({
            default: module.CallRecordingById,
        }),
    ),
);
const AddCampaign = lazy(() =>
    import('../pages/campaigns/Campaigns/AddCampaign').then(module => ({
        default: module.AddCampaign,
    })),
);
const ModifyCampaign = lazy(() =>
    import('../pages/campaigns/Campaigns/ModifyCampaign').then(module => ({
        default: module.ModifyCampaign,
    })),
);

const CallHours = lazy(() =>
    import('../pages/settings/CallHours').then(module => ({
        default: module.CallHours,
    })),
);

const Invitation = lazy(() =>
    import('../pages/settings/Invitation').then(module => ({
        default: module.Invitation,
    })),
);

const Teams = lazy(() =>
    import('../pages/settings/Teams').then(module => ({
        default: module.Teams,
    })),
);

const ApiKeys = lazy(() =>
    import('../pages/settings/ApiKeys').then(module => ({
        default: module.ApiKeys,
    })),
);

const Supervisors = lazy(() =>
    import('../pages/settings/Supervisors').then(module => ({
        default: module.Supervisors,
    })),
);

const AddSupervisor = lazy(() =>
    import('../pages/settings/Supervisors/AddSupervisor').then(module => ({
        default: module.AddSupervisor,
    })),
);

const CallHoursById = lazy(() =>
    import('../pages/settings/CallHours/CallHourById').then(module => ({
        default: module.CallHoursById,
    })),
);

const SupervisorById = lazy(() =>
    import('../pages/settings/Supervisors/SupervisorById').then(module => ({
        default: module.SupervisorById,
    })),
);
const AddCallHour = lazy(() =>
    import('../pages/settings/CallHours/AddCallHour').then(module => ({
        default: module.AddCallHour,
    })),
);

const ModifyCallHours = lazy(() =>
    import('../pages/settings/CallHours/ModifyCallHoursById').then(module => ({
        default: module.ModifyCallHours,
    })),
);

const PageNotFound = lazy(() =>
    import('../pages/PageNotFound').then(module => ({
        default: module.PageNotFound,
    })),
);

const UserActivation = lazy(() =>
    import('../pages/UserActivation').then(module => ({
        default: module.UserActivation,
    })),
);

const ForgotPassword = lazy(() =>
    import('../pages/ForgotPassword').then(module => ({
        default: module.ForgotPassword,
    })),
);

const AddLeads = lazy(() =>
    import('../pages/lists/Lists/AddLeads').then(module => ({
        default: module.AddLeads,
    })),
);

const LeadMappingByUploadId = lazy(() =>
    import('../pages/lists/Lists/LeadMappingByUploadId').then(module => ({
        default: module.LeadMappingByUploadId,
    })),
);

const UploadsByListId = lazy(() =>
    import('../pages/lists/Lists/UploadsByListId').then(module => ({
        default: module.UploadsByListId,
    })),
);
const LeadsByListId = lazy(() =>
    import('../pages/lists/Lists/LeadsByListId').then(module => ({
        default: module.LeadsByListId,
    })),
);
const AddLead = lazy(() =>
    import('../pages/lists/Lists/AddLead').then(module => ({
        default: module.AddLead,
    })),
);

const LeadById = lazy(() =>
    import('../pages/lists/Lists/LeadById').then(module => ({
        default: module.LeadById,
    })),
);
const Bots = lazy(() =>
    import('../pages/agents/Bots').then(module => ({
        default: module.Bots,
    })),
);

const AddBots = lazy(() =>
    import('../pages/agents/Bots/AddBots').then(module => ({
        default: module.AddBots,
    })),
);

const BotInfo = lazy(() =>
    import('../pages/agents/Bots/BotInfo').then(module => ({
        default: module.BotInfo,
    })),
);

const BotFunction = lazy(() =>
    import('../pages/agents/Bots/BotFunction').then(module => ({
        default: module.BotFunction,
    })),
);

const BotFlow = lazy(() =>
    import('../pages/agents/Bots/BotFlow').then(module => ({
        default: module.BotFlow,
    })),
);

const BotPublish = lazy(() =>
    import('../pages/agents/Bots/BotPublish').then(module => ({
        default: module.BotPublish,
    })),
);

const Billing = lazy(() =>
    import('../pages/billings/Billing').then(module => ({
        default: module.Billing,
    })),
);

const CurrentPlan = lazy(() =>
    import('../components/Subscription/Subscriptions').then(module => ({
        default: module.Subscriptions,
    })),
);

interface Props { }

export const Routes: React.FC<Props> = () => {
    const { user } = useAuthContext();
    const currentLocation = useLocation();
    const location = currentLocation;
    if (currentLocation.search === "?redirect=/auth/login") {
        location.pathname = "/"
    }
    const userData = user ? JSON.parse(user) : '';
    const active_role = localStorage.getItem('active_role');
    const selectedOrg = localStorage.getItem('selectedOrg');
    const selectedOrgPlan = localStorage.getItem('selectedOrgPlan');
    return (
        <>
            {user && user !== undefined && userData !== undefined ? (
                <>
                    <MainLayout>
                        <Switch>
                            <Route
                                path="/"
                                exact
                                render={
                                    selectedOrg && selectedOrgPlan !== 'free'
                                        ? withSuspense(Dashboard)
                                        : withSuspense(FloatDashboard)
                                }
                            />
                            <Route
                                path="/agents/phones"
                                exact
                                render={
                                    checkPermission('agents.phones', active_role)
                                        ? withSuspense(Phones)
                                        : withSuspense(PageNotFound)
                                }
                            />
                            <Redirect exact path="/agents" to={'/agents/phones'} />
                            <Route
                                path="/agents/phones/add"
                                exact
                                render={withSuspense(AddPhone)}
                            />
                            <Route
                                path="/agents/phones/:id"
                                exact
                                render={withSuspense(PhoneById)}
                            />
                            <Route
                                path="/agents/ai-agents"
                                exact
                                render={withSuspense(Agents)}
                            />
                            <Route
                                path="/agents/bots"
                                exact
                                render={withSuspense(Bots)}
                            />
                            <Route
                                path="/agents/bots/add"
                                exact
                                render={withSuspense(AddBots)}
                            />
                            <Redirect
                                path="/agents/bots/:id"
                                exact
                                to={`/agents/bots/:id/general`}
                            />
                            <Route
                                path="/agents/bots/:id/general"
                                exact
                                render={withSuspense(BotInfo)}
                            />
                            <Route
                                path="/agents/bots/:id/function"
                                exact
                                render={withSuspense(BotFunction)}
                            />
                            <Route
                                path="/agents/bots/:id/flow"
                                exact
                                render={withSuspense(BotFlow)}
                            />
                            <Route
                                path="/agents/bots/:id/publish"
                                exact
                                render={withSuspense(BotPublish)}
                            />
                            <Route
                                path="/agents/active-calls"
                                exact
                                render={withSuspense(ActiveCalls)}
                            />
                            <Route
                                path={'/not-found'}
                                exact
                                render={withSuspense(PageNotFound)}
                            />
                            <Route
                                path="/agents/ai-agents/:id"
                                exact
                                render={withSuspense(AgentById)}
                            />
                            <Route
                                path="/call-history/advanced"
                                exact
                                render={withSuspense(CdrReports)}
                            />

                            <Route
                                path="/call-history/advanced/:id"
                                exact
                                render={withSuspense(CdrReportById)}
                            />
                            <Route
                                path="/campaigns"
                                exact
                                render={withSuspense(Campaigns)}
                            />
                            <Route
                                path="/campaigns/add"
                                exact
                                render={withSuspense(AddCampaign)}
                            />
                            <Route
                                path="/campaigns/:id/modify"
                                exact
                                render={withSuspense(ModifyCampaign)}
                            />
                            <Route
                                path="/campaigns/:id"
                                exact
                                render={withSuspense(CampaignById)}
                            />
                            <Route
                                path="/campaigns/:id/supervisors"
                                exact
                                render={withSuspense(CampaignsQueueMembers)}
                            />
                            <Route
                                path="/lists"
                                exact
                                render={withSuspense(Lists)}
                            />
                            <Route
                                path="/lists/add-leads"
                                exact
                                render={withSuspense(AddLeads)}
                            />
                            <Route
                                path="/lists/:listId/uploads/:id"
                                exact
                                render={withSuspense(LeadMappingByUploadId)}
                            />
                            <Route
                                path="/lists/:listId/uploads/:id/mapping"
                                exact
                                render={withSuspense(AddMappingForFile)}
                            />
                            <Route
                                path="/lists/add"
                                exact
                                render={withSuspense(AddList)}
                            />
                            <Route
                                path="/lists/:id"
                                exact
                                render={withSuspense(ListById)}
                            />
                            <Route
                                path="/lists/:id/uploads"
                                exact
                                render={withSuspense(UploadsByListId)}
                            />
                            <Route
                                path="/lists/:id/leads"
                                exact
                                render={withSuspense(LeadsByListId)}
                            />
                            <Route
                                path="/lists/:listId/leads/add"
                                exact
                                render={withSuspense(AddLead)}
                            />
                            <Route
                                path="/lists/:listId/leads/:leadId"
                                exact
                                render={withSuspense(LeadById)}
                            />
                            <Route
                                path="/lists/:id/modify"
                                exact
                                render={withSuspense(ModifyList)}
                            />
                            <Route
                                path="/call-history/basic"
                                exact
                                render={withSuspense(CallRecording)}
                            />
                            <Route
                                path="/call-history/basic/:id"
                                exact
                                render={withSuspense(CallRecordingById)}
                            />
                            <Redirect
                                exact
                                path="/call-history/"
                                to={'/call-history/basic'}
                            />
                            <Route
                                path="/settings/call-hours"
                                exact
                                render={withSuspense(CallHours)}
                            />
                            <Route
                                path="/settings/teams"
                                exact
                                render={withSuspense(Teams)}
                            />
                            <Route
                                path="/settings/api-keys"
                                exact
                                render={withSuspense(ApiKeys)}
                            />
                            <Route
                                path="/settings/supervisors"
                                exact
                                render={withSuspense(Supervisors)}
                            />
                            <Route
                                path="/settings/supervisors/add"
                                exact
                                render={withSuspense(AddSupervisor)}
                            />
                            <Route
                                path="/settings/supervisors/:id"
                                exact
                                render={withSuspense(SupervisorById)}
                            />
                            <Route
                                path="/join-org/:id"
                                exact
                                render={withSuspense(Invitation)}
                            />
                            <Route
                                path="/settings/call-hours/add"
                                exact
                                render={withSuspense(AddCallHour)}
                            />
                            <Route
                                path="/settings/call-hours/:id/modify"
                                exact
                                render={withSuspense(ModifyCallHours)}
                            />
                            <Route
                                path="/settings/call-hours/:id"
                                exact
                                render={withSuspense(CallHoursById)}
                            />
                            <Route
                                path="/settings/carriers"
                                exact
                                render={withSuspense(Carriers)}
                            />
                            <Route
                                path="/settings/carriers/add"
                                exact
                                render={withSuspense(AddCarrier)}
                            />
                            <Route
                                path="/settings/carriers/add-custom-carrier"
                                exact
                                render={withSuspense(AddCustomCarrier)}
                            />
                            <Route
                                path="/settings/carriers/add-dialsense-carrier"
                                exact
                                render={withSuspense(AddDialSenseCarrier)}
                            />
                            <Route
                                path="/settings/carriers/:id"
                                exact
                                render={withSuspense(CarrierById)}
                            />
                            <Route
                                path="/settings/carriers/:id/modify"
                                exact
                                render={withSuspense(ModifyCarrier)}
                            />
                            <Route
                                path="/billing/plans"
                                exact
                                render={withSuspense(Billing)}
                            />
                            <Route
                                path="/billing"
                                exact
                                render={withSuspense(CurrentPlan)}
                            />
                            <Route
                                path="/profile"
                                exact
                                render={withSuspense(Profile)}
                            />
                            <Redirect
                                exact
                                path="/settings"
                                to={'/settings/call-hours'}
                            />

                            <Route
                                path="/not-found"
                                exact
                                render={withSuspense(PageNotFound)}
                            />

                            <Route
                                path="/login"
                                exact
                                render={() => <Redirect to="/" />}
                            />
                            <Redirect to="/not-found" />
                        </Switch>
                    </MainLayout>

                </>
            ) : (
                <>
                    <Switch>
                        <Route
                            path="/login"
                            exact
                            render={withSuspense(Login)}
                        />
                        <Route
                            path="/signup"
                            exact
                            render={withSuspense(Signup)}
                        />
                        <Route
                            path="/forgotpassword"
                            exact
                            render={withSuspense(ForgotPassword)}
                        />
                        <Route
                            path="/activate/:id"
                            exact
                            render={withSuspense(UserActivation)}
                        />
                        <Route
                            path="/reset-password/:id"
                            exact
                            render={withSuspense(ResetPassword)}
                        />
                        <Redirect
                            exact
                            path={`/:path`}
                            to={`/login/?redirect=${location.pathname}`}
                        />
                        <Redirect
                            exact
                            path={`/:path/:path1`}
                            to={`/login/?redirect=${location.pathname}`}
                        />
                        <Redirect
                            exact
                            path={`/:path/:path1/:path2`}
                            to={`/login/?redirect=${location.pathname}`}
                        />
                        <Redirect
                            exact
                            path={`/:path/:path1/:path2/:path3`}
                            to={`/login/?redirect=${location.pathname}`}
                        />
                        <Redirect
                            exact
                            path={`/:path/:path1/:path2/:path3/:path4`}
                            to={`/login/?redirect=${location.pathname}`}
                        />
                        <Redirect to="/login" />
                    </Switch>
                </>
            )}
        </>
    );
};
