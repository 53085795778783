import React from 'react';
import { Spinner } from '../../components/Svgs';
import { H5, PX3 } from '../../components/Typography';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    children?: React.ReactNode;
    type?: 'button' | 'submit';
    size?: 'sm' | 'md' | 'lg' | 'xs';
    uiType?: 'success' | 'warning' | 'danger' | 'info' | 'default' | 'brand' | 'white';
    Icon?: React.ReactNode;
    variant?: 'outline' | 'fill';
    disabled?: boolean;
    loading?: boolean;
    rounded?: string;
    IconRight?: React.ReactNode;
    onClick?: any;
    className?: string;
    alignItems?: string;
}

export const Button: React.FC<Props> = ({
    children,
    type = "button",
    size = 'sm',
    uiType = 'default',
    Icon,
    variant = 'fill',
    disabled = false,
    loading = false,
    rounded = "default",
    IconRight,
    onClick,
    className = "",
    alignItems = "center",
    ...props
}) => {
    const sizeClass = getButtonPadding({ size });
    const bgClass = getButtonBg({ uiType, variant, disabled });
    //   const uiTypeClass = getUiTypeClasses({uiType});

    let classes = `${sizeClass} ${bgClass} ${"rounded-" + rounded} transition-all duration-400 flex ${"items-" + alignItems} ${"justify-" + alignItems}`;

    classes += " " + className;

    if (typeof children === 'string') {
        const textColor = getButtonTextColorClass({
            uiType,
            disabled,
            variant,
        });
        const textClasses = `${textColor}`;
        return (
            <button onClick={onClick} disabled={disabled} className={classes} type={type} {...props}>
                {
                    loading ? (
                        <Spinner color="#ffffff" />
                    ) : (
                        <>
                            {Icon}
                            <div
                                className={`${Icon ? 'ml-2' : ''}${IconRight ? ' mr-2' : ''
                                    }`}
                            >
                                {size === 'xs' ? (
                                    <PX3 colorClass={textClasses}>{children}</PX3>
                                ) : (
                                    <H5 colorClass={textClasses}>{children}</H5>
                                )}
                            </div>
                            {IconRight}
                        </>
                    )}
            </button>
        );
    }

    return (
        <button onClick={onClick} disabled={disabled} className={classes} {...props}>
            {loading ? (
                <Spinner
                    color="#fff"
                />
            ) : (
                <>
                    {Icon}
                    <div
                        className={`${Icon ? 'ml-2' : ''}${IconRight ? ' mr-2' : ''
                            }`}
                    >
                        {children}
                    </div>
                    {IconRight}
                </>
            )}
        </button>
    );
};

const getButtonPadding = ({ size }: { size: string }) => {
    switch (size) {
        case 'xs':
            return 'py-1 px-3';
        case 'sm':
            return 'py-2 px-4';
        case 'md':
            return 'py-2 px-9';
        case 'lg':
            return 'py-4 px-12';
        default:
            return 'py-2 px-4';
    }
};

const getButtonBg = ({
    uiType,
    variant,
    disabled,
}: {
    uiType: string;
    variant: string;
    disabled: boolean;
}) => {
    if (disabled) {
        return 'bg-gray-400 cursor-not-allowed';
    }
    if (variant === 'fill') {
        switch (uiType) {
            case 'white':
                return 'bg-white hover:bg-brand-l4';
            case 'success':
                return 'bg-green hover:bg-dark-green';
            case 'warning':
                return 'bg-orange hover:bg-dark-orange';
            case 'danger':
                return 'bg-red-400 hover:bg-red-600';
            case 'info':
                return 'bg-blue hover:bg-dark-blue';
            case 'brand':
                return 'bg-brand hover:bg-brand-d1';
            default:
                // return 'bg-brand-l4 shadow-small hover:bg-hover-white';
                return 'bg-gray-800 hover:bg-black';
        }
    } else if (variant === 'outline') {
        switch (uiType) {
            case 'success':
                return 'border border-green hover:shadow-btn-hover-success';
            case 'warning':
                return 'border border-orange hover:shadow-btn-hover-warning';
            case 'danger':
                return 'border border-red hover:shadow-btn-hover-danger';
            case 'info':
                return 'border border-blue hover:shadow-btn-hover-info';
            default:
                return 'border border-gray-100 hover:shadow-small';
        }
    } else {
        return '';
    }
};

const getButtonTextColorClass = ({
    uiType,
    disabled,
    variant,
}: {
    uiType: string;
    disabled: boolean;
    variant: string;
}) => {
    // if (uiType === 'default') return 'text-gray-999';
    if (disabled || variant === 'fill') {
        switch (uiType) {
            case 'white':
                return 'text-black';
            case 'brand':
                return 'text-white';
            case 'success':
                return 'text-white';
            case 'warning':
                return 'text-black';
            case 'danger':
                return 'text-white';
            case 'info':
                return 'text-black';
            default:
                return 'text-white';
        }
    }
    else {
        switch (uiType) {
            case 'brand':
                return 'text-black';
            case 'success':
                return 'text-green';
            case 'warning':
                return 'text-orange';
            case 'danger':
                return 'text-red';
            case 'info':
                return 'text-blue';
            default:
                return 'text-black';
        }
    }
};