import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import {ReactQueryDevtools} from 'react-query/devtools';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import {Routes} from './components/Routes';
import {AuthProvider} from './contexts/AuthContext';
import {SidebarProvider} from './contexts/SidebarContext';

function App() {

    return (
        <div className="App bg-gray-100 transition-all">
            <AuthProvider>
                <SidebarProvider>
                    <>
                        <Routes />
                        <ToastContainer />
                    </>
                </SidebarProvider>
            </AuthProvider>
            <ReactQueryDevtools initialIsOpen={false} />
        </div>
    );
}

export default App;
