import React, { createContext, useContext, useState } from 'react';
// import { setAccessToken } from '../config/accessToken';

export const SidebarContext = createContext<{
    sidebarActive: boolean;
    setSidebarActive: React.Dispatch<React.SetStateAction<boolean>>;
}>({
    sidebarActive: false,
    setSidebarActive: () => {},
});

interface Props {
    children: React.ReactElement;
}

export const SidebarProvider: React.FC<Props> = ({ children }) => {
    const [sidebarActive, setSidebarActive] = useState(true);

    return (
        <SidebarContext.Provider
            value={{
                sidebarActive,
                setSidebarActive,
            }}
        >
            {children}
        </SidebarContext.Provider>
    );
};

export const useSidebarContext = () => useContext(SidebarContext);

export default SidebarContext;
